




























import { Component, Watch, Vue } from "vue-property-decorator";
import ArticleList from "@/components/shop/article/list/ArticleList.vue";
import AlertModule, { AlertType } from "@/store/modules/alert";
import CartModule from "@/store/modules/cart";
import axios, { APIResponse } from "@/plugins/axios";
import { Article, ArticleCategoryDetails } from "@/types/shop/article";

/**
 * ShopArticleCategoryDetails view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ArticleList,
    },
})
export default class ShopArticleCategoryDetails extends Vue {
    private articleCategory: ArticleCategoryDetails | null = null;
    private articleQuantities: { [key: number]: number } = {};

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        if (!("slug" in this.$route.params)) {
            this.$router.push({ name: "shop" });
        }

        this.onCategorySlugChanged();
    }

    /**
     * Navigate to ShopMainPage when locale changes
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Watch("$root.$i18n.locale")
    private navigateToShopMainPage() {
        this.$router.push({ name: "shopMainPage" });
    }

    /**
     * Function will be automatic called by Vue.js when category slug was changed
     * Function fetches category by name
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Watch("$route.params.slug")
    private async onCategorySlugChanged() {
        try {
            this.articleCategory = await this.fetchCategoryByName(
                this.$route.params.slug
            );

            this.articleQuantities = {};
            this.articleCategory.articles.forEach((article) => {
                this.articleQuantities[article.id] = 1;
            });
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches article category by specified name
     *
     * @param name category name
     *
     * @returns Promise<ArticleCategory[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchCategoryByName(
        name: string
    ): Promise<ArticleCategoryDetails> {
        const response = (
            await axios.get<APIResponse<ArticleCategoryDetails>>(
                "/articles/categories/" + name
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Handler for article click event
     *
     * @param article clicked article
     *
     * @author Kevin Danne <danne@skiba-procomputer>
     */
    private articleClicked(article: Article) {
        this.$router.push({
            name: "shopArticleDetails",
            params: {
                id: article.id.toString(),
            },
        });
    }

    private addArticleToCart(article: Article, quantity: number) {
        CartModule.addArticle({
            commissionId: -1,
            commissionName: null,
            article,
            quantity,
        })
            .then(() => {
                this.$router.push({
                    name: "shoppingCart",
                });
            })
            .catch((err) => {
                const errorMessage =
                    err instanceof Error ? err.message : (err as string);

                AlertModule.showAlert({
                    type: AlertType.ERROR,
                    message: errorMessage,
                });
            });
    }
}
